.ais-Hits {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.ais-Hits-loadPrevious {
  display: none;
}

.ais-Hits-loadMore {
  margin-top: 10px;
  color: #fff;
  background-color: #5c88da;
  height: 2.5rem;
  border-radius: 0.25rem;
  font-weight: 600;
  transition: all 250ms;
  width: 10%;
  place-self: center;
}

.ais-Hits-loadMore--disabled {
  display: none;
}

.ais-Hits-loadMore {
  margin-bottom: 20px;
  padding: 8px;
  width: auto;
}

.ais-Hits-list {
  padding: 1rem;
  list-style: none;
  overflow-y: scroll;
}

.ais-Hits-list {
  list-style: none;
}

main {
  align-items: center;
  background-color: #d9d7f1;
  display: flex;
  flex-direction: row;
  width: 34rem;
  position: relative;
  overflow-y: 'scroll';
}

.result-block {
  animation: slide-in ease-in-out 0.8s;
}

.item {
  animation: slide-in ease-in-out 0.8s;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-1.5em);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
