.spinner-container {
  z-index: 5000;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading-spinner {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
